<template>
  <div>
    <p>
      <u>Nexus HQ</u>
      <br>- Nexus HQ has launched! Nexus HQ is a new website where you can see look up info about your minifigures and the equipment in LEGO Universe. This is just the beginning for the Nexus HQ.
    </p>
    <p>
      <u>Enemies</u>
      <br>- Hammerlings should now move correctly in the Venture Explorer instance.
    </p>
    <p>
      <u>Building</u>
      <br>- If a player’s model is rejected, the player will now receive a message saying so.<br>
    </p>
    <p>
      <u>Frostburgh achievements</u>
      <br>- Frostburgh racetrack achievements should now count towards the “Go Outside and Play” racing meta-achievement.
    </p>
    <p>
      <u>Game Economy</u>
      <br>- Faction token and coin payouts for the Survival, Car Racing and Shooting Gallery have been increased slightly.
    </p>
    <p>
      <u>New Feature</u>
      <br>- When players complete the mission “Something in the Maelstrom,” a mysterious presence in the Maelstrom makes himself known. This is only seen one time on any play-through, but Paradox is developing a way to look into the Maelstrom from Nexus Tower, so characters who have already completed that mission will be able to see it there.
    </p>
  </div>
</template>
